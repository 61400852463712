/* ******************** */
/* Breakpoint Variables */
/* ******************** */


/* ********************* */
/* Breakpoint Variables */
/* ********************* */

$breakpoint-xs: 575.98px; // Extra Small Devices
$breakpoint-sm: 767.98px; // Small Devices, Tablets
$breakpoint-md: 991.98px; // Medium Devices, Desktops
$breakpoint-lg: 1199.98px; // Large Devices, Wide Screens
$breakpoint-xl: 1399.98px; // Extra Large Devices, Wide Screens


/* ****************** */
/* Breakpoint Mixins */
/* ****************** */

@mixin breakpoint-xs {
    @media (min-width: $breakpoint-xs) {
        @content;
    }
}

@mixin breakpoint-sm {
    @media (min-width: $breakpoint-sm) {
        @content;
    }
}

@mixin breakpoint-md {
    @media (min-width: $breakpoint-md) {
        @content;
    }
}

@mixin breakpoint-lg {
    @media (min-width: $breakpoint-lg) {
        @content;
    }
}

@mixin breakpoint-xl {
    @media (min-width: $breakpoint-xl) {
        @content;
    }
}
