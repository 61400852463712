/* ********* */
/* Typography */
/* ********* */


/* **************** */
/* Font Size Theming */
/* **************** */

:root {
    --font-size-xs: 0.875rem; // 14px
    --font-size-s: 1rem;      // 16px
    --font-size-m: 2rem;      // 32px
    --font-size-l: 3rem;      // 48px
    --font-size-xl: 3.5rem;   // 56px
    --font-size-xxl: 4rem;    // 64px
    --font-size-xxxl: 4.5rem; // 72px
    --font-size-xxxxl: 5rem;  // 80px
}

/* ***************** */
/* Font Weight Theme */
/* ***************** */

:root {
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
}


:root {
    --root-font-size: 3.6vw;
}

@include breakpoint-xs {
    :root {
        --root-font-size: 2.8vw;
    }
}

@include breakpoint-sm {
    :root {
        --root-font-size: 2.4vw;
    }
}

@include breakpoint-md {
    :root {
        --root-font-size: 1.6vw;
    }
}

@include breakpoint-lg {
    :root {
        --root-font-size: 1vw;
    }
}

@include breakpoint-xl {
    :root {

    }

    // Gewoon voor fluid gaan, staat consistenter :)
    // Van S, M, L, XL, XXL Font size, naar specifieke dus bijvoorbeeld --heading-1-font-size = xxl font size. Custom properties blijven nesten.

    // Of clampen met steps.
    // Denk fluid aangezien de verhouding hetzelfde lijkt, moet alleen schone manier vinden om te implementeren.
}
