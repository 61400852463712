.works-page {
	width: 100%;
	height: auto;
}

.works-page .site-header {
	margin-bottom: 5rem;

}

.works-page__content{
	width: 100%;
	height: auto;
}

.works-page main {

	width: 100%;
	height: auto;
}