:root {
--desktop-site-margin: 2rem;
--desktop-grid-column-structure: repeat(12, minmax(1vw, 1fr));
--desktop-grid-column-gutter: 2rem;

--mobile-site-margin: 1rem;
--mobile-grid-column-structure: repeat(4, minmax(1vw, 1fr));
--mobile-grid-column-gutter: 1rem;
--mobile-grid-row-gutter: 2rem;


--sm-site-margin: 2rem;
--sm-grid-column-structure: repeat(12, minmax(1vw, 1fr));
--sm-grid-column-gutter: 2rem;

--lg-site-margin: 2rem;
--lg-grid-column-structure: repeat(12, minmax(1vw, 1fr));
--lg-grid-column-gutter: 2rem;

}

