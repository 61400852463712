@import '../../../styles/main.scss';


span {
    display: block;
}

/* Root element of the footer */
.coming-soon-footer {
    width: 100%;
    height: auto;
}

/* Inner container of the footer */
.coming-soon-footer__inner {
    padding: 0 var(--mobile-site-margin) var(--mobile-site-margin) var(--mobile-site-margin);

    display: grid;
    grid-template-columns: var(--mobile-grid-column-structure);
    grid-column-gap: var(--mobile-grid-column-gutter);

    width: 100%;
    height: auto;

    @include breakpoint-lg {
        grid-template-columns: var(--lg-grid-column-structure);
        grid-column-gap: var(--lg-grid-column-gutter);

        align-items: end;
    }
}

/* Content section of the footer */
.coming-soon-footer__content {
    grid-column: 1 / -1;
    
    margin-bottom: 2rem;

    @include breakpoint-lg {
        grid-column: 1 / span 4;
        margin-bottom: 0;
    }
}

/* Paragraph that groups the phrases together */
.coming-soon-footer__sentence {
    font-family: var(--font-primary);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-light);

    line-height: 112%;

    color: var(--text-color-secondary);
    mix-blend-mode: difference;
}

/* Container for each phrase for the slide-up animation effect */
.coming-soon-footer__phrase-container {
}

/* Individual phrase span */
.coming-soon-footer__phrase {
    display: block;
}

/* Contact section of the footer */
.coming-soon-footer__contact {
    grid-column: 1 / -1;

    width: 100%;
    height: auto;

    @include breakpoint-lg {
        grid-column: 10 / span 3;

        display: grid;
        grid-template-columns: subgrid;
    }
}

/* Wrapper for contact information for additional styling or layout */
.coming-soon-footer__contact-wrapper {
    width: 100%;
    height: auto;

    @include breakpoint-lg {
        display: grid;
        grid-template-columns: subgrid;
    }
}

/* Contact section title */
.coming-soon-footer__contact-title-container {
}

/* Contact section title */
.coming-soon-footer__contact-title {
    margin-bottom: 1.5rem;

    font-family: var(--font-secondary);
    font-size: var(--font-size-xs);

    @include breakpoint-lg {
        margin-bottom: 0;

        line-height: 100%;

        text-align: end;
    }
}

/* List of contact items */
.coming-soon-footer__contact-list {
    display: flex;
    flex-direction: column;

    gap: 1.5rem;

    @include breakpoint-lg {
        grid-column: span 2;
    }
}

/* Individual contact item */
.coming-soon-footer__contact-item {
    line-height: 100%;
}

/* Term in the contact list, like 'Phone' or 'Mail' */
.coming-soon-footer__contact-term {
    margin-bottom: .5rem;

    font-family: var(--font-primary);
    font-size: var(--font-size-xs);

    color: var(--text-color-tertiary);
    mix-blend-mode: difference;
}

/* Box wrapping the contact term for additional styling */
.coming-soon-footer__contact-term-box {

}

/* Contact detail like phone number or email address */
.coming-soon-footer__contact-detail {
}

/* Box wrapping the contact detail for additional styling */
.coming-soon-footer__contact-detail-box {
}

/* Links in the contact detail */
.coming-soon-footer__contact-link {
    font-family: var(--font-primary);
    font-size: var(--font-size-xs);

    text-decoration: none;

    color: var(--text-color-primary);
}

.coming-soon-footer__contact-link:hover {
    color: var(--text-color-tertiary);
}
