@import "../../../styles/main.scss";

:root {
    --gallery-title-font-family: var(--font-secondary);
    --gallery-title-font-size: 4rem;

    --gallery-title-counter-font-family: var(--font-primary);
    --gallery-title-counter-font-size: 0.875rem;
    --gallery-title-counter-text-color: var(--color-oil-black-50);

    --gallery-filter-list-item-spacing: var(--spacing-xs);
}


.portfolio-gallery {
    overflow-x: hidden;
  }
  
  .portfolio-gallery__wrapper {
    /* Styles for the wrapper div inside the portfolio gallery */
  }
  
  .portfolio-gallery__header {
    margin-bottom: var(--spacing-l);

    width: 100%;
    height: auto;
  }

  .portfolio-gallery__header-inner {
    margin: 0;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
      padding: 0 var(--desktop-site-margin);

      display: grid;
      grid-template-columns: var(--desktop-grid-column-structure);
      grid-column-gap: var(--desktop-grid-column-gutter);
    }
  }
  
  .portfolio-gallery__title-container {
    padding: 0 var(--mobile-site-margin);
    margin-bottom: var(--spacing-xs);

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
      grid-column: 1 / span 8;
    }
  }
  
  .portfolio-gallery__title {
    display: inline-block;

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;

    align-items: flex-start;
    justify-content: flex-start;

    font-family: var(--gallery-title-font-family);
    font-size: var(--gallery-title-font-size);

    line-height: 1;
  }

  .portfolio-gallery__title-word--emphasize {
    font-family: var(--font-primary);
  }
  
  .portfolio-gallery__count {
    font-family: var(--gallery-counter-font-family);
    font-size: var(--gallery-title-counter-font-size);

    color: var(--gallery-title-counter-text-color);
  }
  
  .portfolio-gallery__filters {
    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
      grid-column: 1 / span 8;

      max-width: 50rem;
    }
  }
  
  .portfolio-gallery__filter-list {
    margin: 0;
    padding: 0 var(--mobile-site-margin);

    overflow-x: scroll;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: var(--gallery-filter-list-item-spacing);

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {

    }
  }
  
  .portfolio-gallery__filter-item {
    list-style: none;

    margin: 0;
    padding: 0;
  }

  .portfolio-gallery__filter-item:nth-child(1) {
    @include breakpoint-xs {
      margin-left: 0;
    }

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
      margin-left: 15.2vw;
    }
  }
  
  .portfolio-gallery__content {
    width: 100%;
    height: auto;
  }
  
  .portfolio-gallery__list {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;

    gap: 4rem;
  }
