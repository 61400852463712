@import "../../../styles/main.scss";

.brand-values__list-item {
    list-style: none;

    margin: 0;
    padding: 0;
}

/* Brand Values Value */
.brand-values__value {
    width: 100%;
    height: auto;
}

/* Brand Value Container */
.brand-value__container {
    padding: 0 var(--mobile-site-margin);

    display: grid;

    grid-template-columns: var(--mobile-grid-column-structure);
    grid-column-gap: var(--mobile-grid-column-gutter);

    align-items: start;

    width: 100%;
    height: auto;

    @include breakpoint-lg {
        padding: 0 var(--desktop-site-margin);

        display: grid;
    
        grid-template-columns: var(--desktop-grid-column-structure);
        grid-column-gap: var(--desktop-grid-column-gutter);
    }
}

/* Brand Value Title */
.brand-value__title {
    grid-column: 1 / span 4;

    margin-bottom: 2rem;

    display: grid;
    grid-template-columns: subgrid;
    grid-column-gap: var(--mobile-grid-column-gutter);

    width: 100%;
    height: auto;

    @include breakpoint-lg {
        grid-column: 1 / -1;
    }
}

.brand-value__title h2 {
    grid-column: 1 / -1;

    margin: 0;
    padding: 0;

    display: grid;
    grid-template-columns: subgrid;
    grid-column-gap: var(--mobile-grid-column-gutter);

    font-family: var(--font-secondary);
    font-size: 1.5rem;

    @include breakpoint-lg {
        font-size: var(--font-size-xxl);
    }

    @include breakpoint-xl {
        font-size: 5rem;
        line-height: 110%;
        background-color: red;
    }
}


/* Brand Value Number */
.brand-value__number {
    grid-column: 1 / span 1;

    align-self: start;
    justify-self: start;
}

/* Brand Value Statement */
.brand-value__statement {
    grid-column: 2 / span 3;

    text-indent: calc(25.4vw - 0.6vmax);

    @include breakpoint-lg {
        grid-column: 3 / -1;

        text-indent: calc(9vw - 0.6vmax);
    }
}

.brand-value__description-container {
    grid-column: 2 / span 3;

    width: 100%;
    height: auto;

    @include breakpoint-lg {
        grid-column: 7 / span 5;
    }
}

/* Brand Value Description */
.brand-value__description {
    font-family: var(--font-primary);
    font-size: 0.875rem;

    color: var(--text-color-tertiary);

    text-indent: calc(25.4vw - 0.6vmax);

    max-width: 32rem;

    @include breakpoint-lg {
        text-indent: calc(9.2vw - 0.6vmax);
    }
}