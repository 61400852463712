/* ******* */
/* Spacing */
/* ******* */

:root {
    --spacing-xs: 0.5rem; // 8px
    --spacing-s: 1rem;   // 16px
    --spacing-m: 2rem;  // 32px
    --spacing-l: 3rem; // 48px
    --spacing-xl: 5rem; // 80px
    --spacing-xxl: 10rem; // 160px

    --spacing-xs-mobile: 1rem;
    --spacing-s-mobile: 2rem;
    --spacing-m-mobile: 3rem;
    --spacing-l-mobile: 4rem;
    --spacing-xl-mobile: 5rem;
    --spacing-xxl-mobile: 6rem;

    --spacing-xs-desktop: 1rem;
    --spacing-s-desktop: 2rem;
    --spacing-m-desktop: 3rem;
    --spacing-l-desktop: 4rem;
    --spacing-xl-desktop: 5rem;
    --spacing-xxl-desktop: 6rem;
}