.home-page {
	width: 100%;
	height: 100dvh;
}

.home-page__content {
	display: flex;

	flex-direction: column;
	
	justify-content: space-between;

	width: 100%;
	height: 100%;
}

.home-page main {
	pointer-events: none;

	position: fixed;

	inset: 0;

	width: 100%;
	height: 100%;
}

.home-page main > div {
	overflow: hidden;

	width: 100%;
	height: 100%;
}