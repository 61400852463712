:root {
    --portfolio-gallery-video-title-text-color: #fff;

    --portfolio-gallery-video-views-font-family: var(--font-primary);
    --portfolio-gallery-video-views-font-size: 0.875rem;
    --portfolio-gallery-video-views-text-color: var(--text-color-secondary);

    --video-views-suffix-font-family: var(--font-secondary);
    --video-views-suffix-font-size: 0.875rem;
}


// Main container for the video item
.portfolio-gallery__video-item {
    margin-right: 1rem;

    list-style: none;

    overflow: hidden;

    flex: 0 0 auto;

    width: 100%;
    aspect-ratio: 9 / 16;

    max-width: 17.5rem;

    border-radius: 1rem;
  }
  
  // Main container for the content of the video item
  .portfolio-gallery__video-item-container {
    width: 100%;
    height: 100%;
  }
  
  // Container for the inner elements like title, video views, and video
  .portfolio-gallery__video-item-content {  
    position: relative;
    
    width: 100%;
    height: 100%;
  }

  .portfolio-gallery__video-item-header {
    z-index: 95;

    padding: 1rem;

    position: absolute;
    
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: auto;
  }
  
  
  // Container for the video title
  .portfolio-gallery__video-title-container {
  }
  
  // Video title
  .portfolio-gallery__video-title {
    mix-blend-mode: difference;

    color: var(--portfolio-gallery-video-title-text-color);
  }
  
  // Container for video statistics (views, icons, etc.)
  .portfolio-gallery__video-item-stats {
    padding: 8px 12px;

    display: flex;
    flex-direction: row;

    gap: .5rem;

    align-items: center;
    justify-content: center;

    border-radius: 99px;

    background-color: rgb(255 255 255 / 10%);

    line-height: 1;

    backdrop-filter: blur(50px);
  }
  
  // Text displaying the number of views
  .portfolio-gallery__video-views {
    font-family: var(--portfolio-gallery-video-views-font-family);
    font-size: var(--portfolio-gallery-video-views-font-size);

    color: var(--portfolio-gallery-video-views-text-color);
  }

  .portfolio-gallery__views-suffix {
    font-family: var(--video-views-suffix-font-family);
    font-size: var(--video-views-suffix-font-size);
  }
  
  // Container for the icon within the video item stats
  .portfolio-gallery__video-item-icon-container {
    width: 1.5rem;
    height: auto;
  }

  .portfolio-gallery__video-item-icon-container svg {
    fill: #fff;

    width: 100%;
    height: auto;
  }
  
  // Container for the video
  .portfolio-gallery__video-container {
    position: absolute;

    inset: 0;

    width: 100%;
    height: 100%;

    &::before {
        content: '';
        
        z-index: 5;

        position: absolute;
        inset: 0;

        background: linear-gradient(to top, black 0%, transparent 100%);
    }
  }
  
  // Video element
  .portfolio-gallery__video {
    object-fit: cover;
    object-position: center center;

    width: 100%;
    height: 100%;
  }
  