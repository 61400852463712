.coming-soon-page {
	width: 100%;
	height: 100dvh;
}

.coming-soon-page__content {
	display: flex;

	flex-direction: column;
	
	justify-content: space-between;

	width: 100%;
	height: 100%;

	background-color: white;
}

.coming-soon-page main {
	pointer-events: none;

	position: fixed;

	inset: 0;

	width: 100%;
	height: 100%;
}

.coming-soon-page main > div {
	overflow: hidden;

	width: 100%;
	height: 100%;
}