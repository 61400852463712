@import '../../../styles/main.scss';

/*
* Base
*/

h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
    margin: 0;
    padding: 0;

    font-family: var(--font-primary);
    font-weight: 400;

    text-rendering: geometricPrecision;
}

h1 {
    --heading-1-font-size: var(--font-size-xxxxl);

   font-size: var(--heading-1-font-size);
}

h2 {
    font-size: var(--font-size-xxxl);
}

h3 {
    font-size: var(--font-size-xxl);
}

h4 {
    font-size: var(--font-size-xl);
}

h5 {
    font-size: var(--font-size-s);
}

h6 {
    font-size: var(--font-size-xs);
}