/* ***** */
/* Fonts */
/* ***** */


/* 
/ Primary Font
*/ 

@font-face {
    font-family: 'Suisse Intl';
    src: url('../assets/fonts/primary_font/suisse_intl_regular.woff2') format('woff2'),
         url('../assets/fonts/primary_font/suisse_intl_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* 
/ Secondary Font
*/ 

@font-face {
    font-family: 'Suisse Works';
    src: url('../assets/fonts/secondary_font/suisse_works_regular.woff2') format('woff2'),
         url('../assets/fonts/secondary_font/suisse_works_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

:root {
    /* Primary Font Stack */
    --font-primary: 'Suisse Intl', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

    /* Secondary Font Stack */
    --font-secondary: 'Suisse Works', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}
