@import '../../styles/main.scss';

.info-page {
    width: 100%;
    height: auto;
}

.info-page__content {
    width: 100%;
    height: auto;
}

.info-page__content Header {
    margin-bottom: 5rem;
}

.info-page__main {
    margin-bottom: 5rem;
}


/* ********************** */
/* Info Page Introduction */
/* ********************** */

/* Introduction Section */
.introduction {
    margin-bottom: 5rem;

    width: 100%;
    height: auto;

    @include breakpoint-lg {
        margin-bottom: var(--spacing-xxl);
    }
}

/* Introduction Container */
.introduction__inner {
    padding: 0 var(--mobile-site-margin);

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
      padding: 0 var(--desktop-site-margin);

      display: grid;
      grid-template-columns: var(--desktop-grid-column-structure);
      grid-column-gap: var(--desktop-grid-column-gutter);
    }
}

/* Introduction Text Container */
.introduction__text-container {
    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        grid-column: 1 / -1;
    }
}

/* Introduction Text */
.introduction__text-container p {
    font-family: var(--font-secondary);
    font-size: 2rem;

    line-height: 1.2;

    text-indent: 50%;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        grid-column: 1 / -1;

        font-size: 5rem;

        text-indent: 25.5%;
    }

    @include breakpoint-xl {
        font-size: clamp(5rem, 6vw, 8rem);
    }
}




/* ********************************* */
/* Info Page Brand Values Structure */
/* ********************************* */

/* Info Page Brand Values */
.info-page__brand-values {
    margin-bottom: 3.5rem;

    // padding: 0 var(--mobile-site-margin);

    width: 100%;
    height: auto;
}

/* Brand Values Header */
.brand-values__header {
    margin-bottom: 3rem;

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        margin-bottom: var(--spacing-xl);
    }
}

.brand-values__header-inner {
    padding: 0 var(--mobile-site-margin);

    display: grid;

    grid-template-columns: var(--mobile-grid-column-structure);
    grid-column-gap: var(--mobile-grid-column-gutter);

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        padding: 0 var(--desktop-site-margin);

        display: grid;

        grid-template-columns: var(--desktop-grid-column-structure);
        grid-column-gap: var(--desktop-grid-column-gutter);
    }
}

/* Brand Values Header Container */
.brand-values__header-container {
    grid-column: 3 / span 2;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        grid-column: 4 / span 8;
    }
}

/* Brand Values Title */
.brand-values__title {
    font-family: var(--font-secondary);
    font-size: 1.5rem;

    font-weight: 500; /* Voor alle H3's */

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        font-size: 4.5rem;
    }
}


.brand-values__list-container {

}

.brand-values__list {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;

    gap: var(--spacing-xl);
}