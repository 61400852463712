/* ******* */
/* Imports */
/* ******* */
@import '../../../styles/main.scss';




/* ****** */
/* Footer */
/* ****** */
.site-footer {
    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {}
}


/* ************ */
/* Footer Inner */
/* ************ */
.site-footer__inner {
    margin: 0 0 var(--mobile-site-margin) 0;
    padding: 0 var(--mobile-site-margin);

    display: grid;
    grid-template-columns: var(--mobile-grid-column-structure);
    grid-column-gap: var(--mobile-grid-column-gutter);

    align-items: end;
    justify-content: end;

    width: 100%;
    height: auto;

    @include breakpoint-xs {
    }

    @include breakpoint-sm {
    }

    @include breakpoint-md {
    }

    @include breakpoint-lg {
        margin: 0 0 var(--desktop-site-margin) 0;
        padding: 0 var(--desktop-site-margin);

        grid-template-columns: var(--desktop-grid-column-structure);
        grid-column-gap: var(--desktop-grid-column-gutter);
    }
}


/* **** */
/* Logo */
/* **** */
.site-footer__logo {
    grid-column: 1 / span 2;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        grid-column: 1 / span 10;
    }
}


/* ****** */
/* Rights */
/* ****** */
.site-footer__rights {
    grid-column: 3 / span 2;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        grid-column: 11 / span 2;
    }
}

/* *********** */
/* Rights Text */
/* *********** */
.site-footer__rights-text {
    display: flex;
    flex-direction: row;

    gap: .5rem;

    font-family: var(--font-primary);
    font-size: 0.675rem;

    color: var(--text-color-primary);

    text-rendering: geometricPrecision;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {}
}
