@import "../../../styles/main.scss";

:root {
  --category-title-font-family: var(--font-secondary);

  --category-title-font-size: 2rem;

  --category-count-font-family: var(--font-primary);

  --category-count-font-size: 0.875rem;
  --category-count-text-color: var(--gallery-title-counter-text-color);
}

.portfolio-gallery__list-item {
    list-style: none;

    margin: 0;
    padding: 0;

    flex: 0 0 auto;
  }

  .portfolio-gallery__list-item.hidden {
    display: none;
}
  
  /* Selector for the article representing a category within the portfolio */
  .portfolio-category {
    
  }
  
  /* Selector for the header section of the portfolio category */
  .portfolio-category__header {
    margin-bottom: var(--spacing-xs);

    width: 100%;
    height: auto;
  }

  .portfolio-category__header-inner {
    padding: 0 var(--mobile-site-margin);

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
      padding: 0 var(--desktop-site-margin);

      display: grid;
      grid-template-columns: var(--desktop-grid-column-structure);
      grid-column-gap: var(--desktop-grid-column-gutter);
    }
  }
  
.portfolio-category__title-container {
  width: 100%;
  height: auto;

  @include breakpoint-xs {}

  @include breakpoint-sm {}

  @include breakpoint-md {}

  @include breakpoint-lg {
    grid-column: 3 / -1;
  }
}

  /* Selector for the title within the portfolio category's header */
  .portfolio-category__title {
    font-family: var(--category-title-font-family);
    font-size: var(--category-title-font-size);
  }
  
  /* Selector for the item count within the category title */
  .portfolio-category__count {
    vertical-align: text-top;

    font-family: var(--category-count-font-family);
    font-size: var(--category-count-font-size);

    color: var(--category-count-text-color);
    
  }
  
  /* Selector for the container of items within a category */
  .portfolio-category__items {
  }
  
  /* Selector for the list of items within a category */
  .portfolio-category__item-list {
    overflow-x: scroll;

    margin: 0;
    padding: 0;

    padding: 0 var(--mobile-site-margin);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
 
    width: 100%;
    height: auto;
  }
  