@import '../../../styles/main.scss';


/* Main header container */
.coming-soon-header {
    width: 100%;
    height: auto;
}

/* Inner container for the header */
.coming-soon-header__inner {
    padding: var(--mobile-site-margin) var(--mobile-site-margin) 0 var(--mobile-site-margin);

    display: grid;
    grid-template-columns: var(--mobile-grid-column-structure);
    grid-column-gap: var(--mobile-grid-column-gutter);

    align-items: end;

    width: 100%;
    height: auto;

    @include breakpoint-lg {
        padding: var(--lg-site-margin) var(--lg-site-margin) 0 var(--lg-site-margin);

        grid-template-columns: var(--lg-grid-column-structure);
        grid-column-gap: var(--lg-grid-column-gutter);
    }
}

/* Container for the wordmark section */
.coming-soon-header__wordmark {
    grid-column: 1 / span 3;

    @include breakpoint-lg {
        grid-column: 1 / span 11;
    }
}

/* Container around the wordmark for animation */
.coming-soon-header__wordmark-container {
}

/* Wordmark SVG styling - additional styles should be applied within the Wordmark component if needed */
.coming-soon-header__wordmark-container svg {
    width: 100%;
    height: auto;
}

/* Container for the copyright section */
.coming-soon-header__copyright {
    grid-column: span 1;
}

/* Styling for the copyright text */
.coming-soon-header__copyright span {
    display: block;

    font-family: var(--font-primary);
    font-size: var(--font-size-xs);

    line-height: 110%;

    color: var(--text-color-tertiary);
}
