/* ******* */
/* Imports */
/* ******* */
@import '../../../styles/main.scss';




/* ****** */
/* Header */
/* ****** */
.site-header {
    z-index: 99;

    margin: var(--mobile-site-margin) 0 0 0;

    position: relative;

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
      margin: var(--desktop-site-margin) 0 0 0;
    }
}


/* ************ */
/* Header Inner */
/* ************ */
.site-header__inner {
    padding: 0 var(--mobile-site-margin);

    display: grid;

    grid-template-columns: var(--mobile-grid-column-structure);
    grid-column-gap: var(--mobile-grid-column-gutter);
    grid-row-gap: var(--mobile-grid-row-gutter);

    align-items: start;

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
      padding: 0 var(--desktop-site-margin);

      grid-template-columns: var(--desktop-grid-column-structure);
      grid-column-gap: var(--desktop-grid-column-gutter);
    }
}


/* **** */
/* Logo */
/* **** */
.site-header__logo {
    grid-column: 1 / span 1;

    font-family: var(--font-secondary);
    font-size: 1rem;

    text-decoration: none;

    line-height: 1;

    color: var(--text-color-primary);

    width: 100%;
    height: auto;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {}
}


/* **** */
/* Menu */
/* **** */
.site-header__menu {
    grid-column: 2 / span 3;

    display: grid;
    grid-template-columns: subgrid;

    align-items: start;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        grid-column: 3 / span 3;
    }
}


/* ************ */
/* Menu Wrapper */
/* ************ */
.site-header__menu-wrapper {
   grid-column: span 1;
   
   @include breakpoint-xs {}

   @include breakpoint-sm {}

   @include breakpoint-md {}

   @include breakpoint-lg {}
}


/* ********** */
/* Menu Title */
/* ********** */
.site-header__menu-title {
    font-family: var(--font-secondary);
    font-size: 1rem;

    font-weight: 100;
    
    text-align: end;
    text-rendering: geometricPrecision;

    line-height: 1;

    @include breakpoint-xs {}

    @include breakpoint-sm {}
 
    @include breakpoint-md {}
 
    @include breakpoint-lg {}
}


/* ********** */
/* Navigation */
/* ********** */
.site-header__nav {
    grid-column: span 2;

    @include breakpoint-xs {}

    @include breakpoint-sm {}
 
    @include breakpoint-md {}
 
    @include breakpoint-lg {}
}


/* *************** */
/* Navigation List */
/* *************** */
.site-header__nav-list {
    list-style: none;

    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;

    gap: .5rem;

    @include breakpoint-xs {}

    @include breakpoint-sm {}
 
    @include breakpoint-md {}
 
    @include breakpoint-lg {}
}


/* *************** */
/* Navigation Item */
/* *************** */
.site-header__nav-item {
    @include breakpoint-xs {}

    @include breakpoint-sm {}
 
    @include breakpoint-md {}
 
    @include breakpoint-lg {}
}

.clip-container {
    overflow: hidden;
}


/* *************** */
/* Navigation Link */
/* *************** */
.site-header__nav-link {
    margin: 0;
    padding: 0;

    mix-blend-mode: difference;

    display: block;

    font-family: var(--secondary-font);
    font-size: 1.5rem;

    text-decoration: none;
    text-rendering: geometricPrecision;

    line-height: 1;

    color: var(--text-color-primary);

    @include breakpoint-xs {}

    @include breakpoint-sm {}
 
    @include breakpoint-md {}
 
    @include breakpoint-lg {
        font-size: 2rem;
    }
}

.site-header__nav-link.disabled {
    opacity: .25;
    cursor:not-allowed;
    color: var(--text-color-primary);
}


/* ******* */
/* Contact */
/* ******* */
.site-header__contact {
    grid-column: 2 / span 3;

    display: grid;
    grid-template-columns: subgrid;

    @include breakpoint-xs {}

    @include breakpoint-sm {}

    @include breakpoint-md {}

    @include breakpoint-lg {
        grid-column: 10 / span 3;
    }
}


/* ********************* */
/* Contact Title Wrapper */
/* ********************* */
.site-header__contact-wrapper {}


/* ************* */
/* Contact Title */
/* ************* */
.site-header__contact-title {
    grid-column: span 1;

    font-family: var(--font-secondary);
    font-size: 1rem;
    
    font-weight: 100;
    
    text-align: end;
    text-rendering: geometricPrecision;

    line-height: 1;

    @include breakpoint-xs {}

    @include breakpoint-sm {}
 
    @include breakpoint-md {}
 
    @include breakpoint-lg {}
}


/* ************ */
/* Contact List */
/* ************ */
.site-header__contact-list {
    grid-column: span 2;

    display: flex;
    flex-direction: column;

    gap: 1rem;

    @include breakpoint-xs {}

    @include breakpoint-sm {}
 
    @include breakpoint-md {}
 
    @include breakpoint-lg {}
}


/* ************ */
/* Contact Item */
/* ************ */
.site-header__contact-item {
}


/* ************ */
/* Contact Term */
/* ************ */
.site-header__contact-term {
    opacity: .4;

    font-family: var(--font-primary);
    font-size: 0.875rem;

    color: var(--text-color-primary);

    @include breakpoint-xs {}

    @include breakpoint-sm {}
 
    @include breakpoint-md {}
 
    @include breakpoint-lg {}
}


/* ************** */
/* Contact Detail */
/* ************** */
.site-header__contact-detail {
}


/* ************ */
/* Contact Link */
/* ************ */
.site-header__contact-link {
    margin: 0;
    padding: 0;

    font-family: var(--font-primary);
    font-size: 0.875rem;

    text-decoration: none;
    text-rendering: geometricPrecision;

    color: var(--text-color-tertiary);

    // color: #fff;

    @include breakpoint-xs {}

    @include breakpoint-sm {}
 
    @include breakpoint-md {}
 
    @include breakpoint-lg {}
}


