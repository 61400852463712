/* ******************* */
/* Global Style Resets */
/* ******************* */

html,
body,
#root {
    margin: 0;
    padding: 0;
    
    width: 100%;
    // height: 100%;

    font-size: var(--root-font-size);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
}

*::-webkit-scrollbar {
    display: none;
}

* {
    -ms-overflow-style: none; 
    scrollbar-width: none;  

    overflow: -ms-autohiding-scrollbar;
}
  


