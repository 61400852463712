/* ***** */
/* Color */
/* ***** */

/* ***************** */
/* Basic Color Theme */
/* ***************** */

:root {
    --highlight: 1px dashed red;

    --color-white: #ffffff;
    --color-black: #000000;
}

/* ******************* */
/* Primary Color Theme */
/* ******************* */

/* ********* */
/* Off White */
/* ********* */

:root {
    --color-off-white-10: #FAF9F6;
    --color-off-white-50: #f2f1ef;
    --color-off-white-100: #e8e7e5;

    --color-off-white-200: #d4d3cf;
    --color-off-white-300: #c1beb9;
    --color-off-white-400: #adaaa5;

    --color-off-white-500: #999793;

    --color-off-white-600: #666462;
    --color-off-white-700: #4d4b48;
    --color-off-white-800: #333231;
    --color-off-white-900: #1a1917;
}


/* ********* */
/* Oil Black */
/* ********* */

:root {
  --color-oil-black-10: #e5e5e5;
  --color-oil-black-50: #bcbcbc;
  --color-oil-black-100: #929292;

  --color-oil-black-200: #696969;
  --color-oil-black-300: #3f3f3f;
  --color-oil-black-400: #262626;

  --color-oil-black-500: #0c0c0c;

  --color-oil-black-600: #0b0b0b;
  --color-oil-black-700: #0a0a0a; 
  --color-oil-black-800: #090909;
  --color-oil-black-900: #080808;
}


/* **************** */
/* Text Color Theme */
/* **************** */

:root {
    --pure-black: #000000;
    --pure-white: #ffffff;
    --secondary-color: #666;
    --background-color: #fff;

    --text-color-primary: var(--pure-black);
    --text-color-secondary: var(--pure-white);
    --text-color-tertiary: #757575;
}