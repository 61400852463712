.u-overflow-hidden {
    overflow-y: hidden;
  }

  .u-transform-up {
    transform: translateY(0%);
  }
  
  .u-transform-down {
    transform: translateY(0);
  }


  .u-emphasize-text {
    font-family: var(--font-secondary);
  }

 
  