
.canvas-container {
    pointer-events: none;

    position: relative;

    width: 100%;
    height: 100%;
}

.canvas-container div {
    /* pointer-events: none !important; */
}

canvas {
    user-select: none;
    touch-action: none; 

    -webkit-tap-highlight-color: transparent;

    display: block;

    width: 100%;
    height: 100%;

    image-rendering: optimizeQuality;
}