@import "../../../styles/main.scss";

:root {
  /* Filter Button Padding */
  --filter-button-padding-x: 0.75rem;
  --filter-button-padding-y: 0.375rem;

  /* Filter Button Font and Label Styling */
  --filter-button-font-family: var(--font-secondary);
  --filter-button-label-size: var(--font-size-xs);
  --filter-button-label-color: var(--color-oil-black-50);
  --filter-button-label-counter-spacing: 0.25rem;

  /* Filter Button Counter Styling */
  --filter-button-counter-font-family: var(--font-primary);

  /* Filter Button Border Styling */
  --filter-button-border-thickness: 1px;
  --filter-button-border-style: solid;
  --filter-button-border-radius: 99px;
  --filter-button-border: var(--filter-button-border-thickness) var(--filter-button-border-style) var(--color-oil-black-50);

  /* Filter Button Background Styling */
  --filter-button-background-color: transparent;

  /* Filter Button Active State Styling */
  --filter-button-active-border: var(--filter-button-border-thickness) var(--filter-button-border-style) var(--color-oil-black-500);
  --filter-button-active-background-color: var(--color-oil-black-500);
  --filter-button-active-label-color: var(--color-off-white-10);
}


/* Base styles for the filter button */
.filter-button {
  margin: 0;
  padding: var(--filter-button-padding-y) var(--filter-button-padding-x);

  cursor: pointer;

  border: var(--filter-button-border);
  border-radius: var(--filter-button-border-radius);

  background-color: var(--filter-button-background-color);
}

/* Styles for when the filter button is active */
.filter-button--active {
  padding: var(--filter-button-padding-y) var(--filter-button-padding-x);
  margin: 0;

  border: var(--filter-button-active-border);

  background-color: var(--filter-button-active-background-color);
}

/* Styles for the content container within the button when active */
.filter-button--active .filter-button__content {
  color: var(--filter-button-active-label-color);
}

/* Styles for the content container within the button */
.filter-button__content {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: var(--filter-button-label-counter-spacing);

  font-family: var(--filter-button-font-family);
  font-size: var(--filter-button-label-size);

  line-height: normal;

  text-wrap: nowrap;

  color: var(--filter-button-label-color);
}

/* Styles for the label inside the button */
.filter-button__label {}

/* Styles for the counter inside the button */
.filter-button__counter {
  font-family: var(--filter-button-counter-font-family);
}